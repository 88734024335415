<template>
  <ElMenu
    :collapse-transition="false"
    :default-active="getDefaultActive[0]"
    :default-openeds="[
      getDefaultActive[0],
      `${getDefaultActive[0]}-${getDefaultActive[1]}`
    ]"
    class="h-full custom-aside-menu"
    :class="`${width <= 1100 ? 'mobile-sidebar' : 'desktop-sidebar'} ${
      drawer ? 'custom-drawer' : ''
    }`"
    :unique-opened="true"
  >
    <div class="custom-aside-menu-items" v-if="width > 1100">
      <!-- eslint-disable-next-line vue/no-v-for-template-key -->
      <template v-for="(item, key) in children" :key="key">
        <UtilsBaseItemGroup
          v-if="item.children"
          :item="item"
          :custom-key="key.toString()"
          @changePath="$emit('changePath')"
        />
        <UtilsBaseItem
          v-else-if="!item.children"
          :item="item"
          :drawer="drawer"
          :custom-key="key.toString()"
          @changePath="$emit('changePath')"
        />
      </template>
    </div>
    <div
      v-if="width > 1100"
      class="custom-aside-menu-button"
      @click="$emit('setDrawer')"
    >
      <Icons
        :name="drawer ? 'Backoffice/toggle' : 'Backoffice/toggle-selected'"
      />
      <span class="label" v-if="drawer">{{ $t('close_sidebar') }}</span>
    </div>
  </ElMenu>
</template>

<script>
import verticalSidebarItemsBackoffice from '~/models/vertical-sidebar-items-backoffice'
import verticalSidebarItemsGuide from '~/models/vertical-sidebar-items-guide'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const route = useRoute()
    const roles = getAuthRoles()
    const { width, height } = useBreakpoints()

    return {
      width,
      route,
      roles
    }
  },
  props: {
    moduleSelected: {
      type: String,
      required: false,
      default: 'home'
    },
    drawer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      moduleSelectedCopy: this.moduleSelected
    }
  },
  computed: {
    modules() {
      if (this.roles.find((i) => i.name === 'Guide')) {
        return verticalSidebarItemsGuide
      }
      return verticalSidebarItemsBackoffice
    },
    moduleToShow() {
      return (
        this.modules.find((i) => i.title === this.moduleSelectedCopy) ?? {
          iconName: 'inicio',
          title: 'home',
          to: '/'
        }
      )
    },
    children() {
      return (
        this.moduleToShow?.children?.map((item) => {
          return {
            ...item,
            to:
              item.to && this.moduleToShow.group
                ? this.moduleToShow.group + '/' + item.to
                : this.moduleToShow.group === '/'
                ? '/'
                : undefined
          }
        }) ?? []
      )
    },
    getDefaultActive() {
      let result = ['0', '0']
      const item = this.moduleToShow
      if (item.group) {
        for (let j = 0; j < item.children.length; j++) {
          const child = item.children[j]
          if (this.route.fullPath.includes(child.to)) {
            result = [
              this.modules
                .findIndex((i) => i.title === this.moduleSelectedCopy)
                ?.toString() ?? '0',
              j.toString()
            ]
            break
          }
        }
      }
      if (!item.group && item.to === this.route.fullPath) {
        result = ['0', '0']
      }
      return result
    }
  },
  watch: {
    moduleSelected(newVal) {
      this.moduleSelectedCopy = newVal
    }
  }
})
</script>

<style scoped lang="scss">
.custom-aside-menu {
  z-index: 7 !important;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 0px !important;
  @media (min-width: 1100px) {
    &.custom-drawer {
      width: 234px;
    }
    &:not(.custom-drawer) {
      width: 64px;
    }
  }
  @media (max-width: 1100px) {
    margin-top: -20px;
    padding-top: 20px;
  }

  .custom-aside-menu-items {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1100px) {
      margin-top: 24px;
    }
    li:not(:first-child) {
      margin-top: 8px;
    }
  }
  .custom-aside-menu-button {
    position: fixed;
    bottom: 30px;
    justify-content: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    width: 234px;
    height: 74px !important;
    padding: 12px;
    padding-top: 28px;
    border-top: 2px solid var(--brand-off-white);
    cursor: pointer;
    i {
      display: inline-block;
      margin-right: 8px;
      margin-left: 12px;
    }
    span {
      display: inline-block;
      color: var(--tins-dark-2);
    }
  }

  @media (min-width: 1100px) {
    &:not(.custom-drawer) {
      .custom-aside-menu-items {
        position: relative;
        left: 0px;
      }
      .custom-aside-menu-button {
        left: -15px;
        width: 64px;
      }
    }
  }
}
</style>