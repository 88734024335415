<template>
  <el-menu-item
    v-if="item.clickable"
    :index="customKey"
    :class="`${itemSelectedClass} ${!drawer ? 'not-drawer' : ''}`"
    @click="item.clickable ? goRoute(item.to) : null"
    :style="`${
      item.clickable
        ? 'cursor: pointer !important'
        : 'cursor: default !important'
    }`"
  >
    <Icons
      :name="
        isActive()
          ? `Backoffice/${item.icon}-selected`
          : `Backoffice/${item.icon}`
      "
      class="mr-2"
    />
    <span v-if="drawer" class="truncate body1-strong">{{
      $t(item.title)
    }}</span>
  </el-menu-item>
</template>

<script setup>
const route = useRoute()
const emits = defineEmits(['changePath'])

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  customKey: {
    type: String,
    required: true
  },
  drawer: {
    type: Boolean,
    required: false,
    default: false
  }
})

const goRoute = (to) => {
  navigateTo(to)
  emits('changePath')
}

const isActive = () => {
  if (route.fullPath.includes('/auth/')) {
    return false
  } else if (props.item.to?.split('/').length >= 3) {
    return route.fullPath.includes(props.item.to)
  } else {
    return props.item.to === route.fullPath
  }
}

const itemSelectedClass = computed(() => {
  if (isActive() && props.drawer) {
    return 'is-active'
  }
  return 'is-not-active'
})
</script>

<style scoped lang="scss">
.el-menu-item {
  height: 48px;
  @media (min-width: 1100px) {
    width: 90%;
    &.not-drawer {
      width: 70%;
      padding-left: 10px !important;
      padding-right: 0 !important;
    }
  }
  @media (max-width: 1100px) {
    width: 93vw;
  }
  &.is-active {
    background-color: var(--sidebar-select) !important;
    border-radius: 8px;
    color: var(--brand-blue) !important;
  }

  &.is-not-active {
    background-color: var(--brand-white) !important;
    color: var(--brand-off-black) !important;
  }
}
</style>
